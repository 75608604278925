<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="d-flex">
        <v-row>
          <v-col cols="12" md="12">
            <v-card height="100%">
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-pen-nib fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Éditeur du site</span>
              </v-card-title>
              <v-card-text>
                <p>Le présent site internet « Mes Services Assurance », accessible à l'adresse :</p>
                <ul>
                  <li>
                    <a href="https://mes-services.anavel.bzh">https://mes-services.anavel.bzh</a>
                  </li>
                </ul>
                <br />

                <p>est édité par la SARL <strong>Anavel</strong></p>
                <p>Siège social : ANAVEL, 1 AV DES PRUNUS 35270 COMBOURG</p>
                <p>Représentants légaux :</p>
                <ul>
                  <li>M. AUDREN David</li>
                  <li>M. LERAY Philippe</li>
                  <li>M. NERAMBOURG Eric</li>
                </ul>
                <br />
                <p>Email : <a href="mailto:contact@anavel.bzh">contact@anavel.bzh</a></p>
                <p>Téléphone : <a href="tel:+0299871212">02 99 73 00 27</a></p>
                <p>Responsable de la Publication Monsieur AUDREN DAVID</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card height="100%">
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-hand fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Données personnelles</span>
              </v-card-title>
              <v-card-text>
                Les informations recueillies à partir de ce formulaire font l’objet d’un traitement informatique destiné
                aux agents d’Anavel pour les finalités suivantes :
                <strong>Gestion des dossiers</strong>
                Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous disposez d’un droit
                d’accès et de rectification aux informations qui vous concernent.
                <br />
                Vous pouvez accéder aux informations vous concernant en adressant une demande au Délégué à la Protection
                des Données (DPO) :
                <a href="mailto:david.audren@anavel.bzh">david.audren@anavel.bzh</a>
                <br />
                Vous pouvez également, pour des motifs légitimes, vous opposer au traitement d’une partie ou de la
                totalité des données vous concernant.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card height="100%">
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-check fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Accès au site</span>
              </v-card-title>
              <v-card-text>
                <p>
                  <a href="https://mes-services.anavel.bzh">ANAVEL</a> s’efforce de permettre l’accès au site
                  <a href="https://mes-services.anavel.bzh">https://mes-services.anavel.bzh</a> 24 heures sur 24, 7
                  jours sur 7.
                </p>
                <p>
                  Cependant, <a href="https://mes-services.anavel.bzh">ANAVEL</a> n’est tenu que d’une obligation de
                  moyen pour ce qui est du fonctionnement et de la continuité du service.
                </p>
                <p>
                  <a href="https://mes-services.anavel.bzh">ANAVEL</a> ne sera donc pas responsables de l’altération ou
                  de l’accès frauduleux à des données et/ou de la transmission accidentelle de données en raison
                  d’interventions malveillantes ou de virus par le biais du service.
                </p>
                <p>
                  Sa responsabilité ne sera pas engagée pour des faits dus à un cas de force majeure ou à des évènements
                  indépendants de sa volonté tels que les pannes et/ou les problèmes d’ordre technique concernant le
                  matériel informatique, les programmes et logiciels ou le réseau Internet pouvant le cas échéant
                  entraîner la suspension ou la cessation du service.
                </p>
                <p>
                  La responsabilité de l’éditeur ne saurait être engagée en cas d’impossibilité d’accès à ce site et/ou
                  d’utilisation des services.
                </p>
                <p>
                  Par ailleurs, <a href="https://mes-services.anavel.bzh">ANAVEL</a> peut être amené à interrompre le
                  site, à tout moment sans préavis, le tout sans droit à indemnités. L’utilisateur reconnaît et accepte
                  que <a href="https://mes-services.anavel.bzh">ANAVEL</a> ne soit pas responsable des interruptions, et
                  des conséquences qui peuvent en découler pour l’utilisateur ou tout tiers.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="d-flex">
        <v-row>
          <v-col cols="12" md="24">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-server fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Hébergeur</span></v-card-title
              >
              <v-card-text>
                <p>Ce site est hébergé par la société OVH.</p>
                <p>
                  SAS au capital de 10 069 020 € RCS Lille Métropole 424 761 419 00045 Code APE 2620Z N° TVA : FR 22 424
                  761 419 Siège social : 2 rue Kellermann – 59100 Roubaix – France
                </p>
                <p><a href="http://ovh.com">http://ovh.com</a></p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-hand-holding-heart fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Engagement</span></v-card-title
              >
              <v-card-text>
                <a href="https://mes-services.anavel.bzh">https://mes-services.anavel.bzh</a> est un site Internet
                d’information, dédié au secteur de l’assurance. Il appartient à l’utilisateur de ce site de prendre
                toutes les mesures appropriées afin de protéger ses propres données et/ou logiciels de leur
                contamination par d’éventuels virus circulant sur le réseau Internet. Notre site collecte des données
                (nom, prénom, adresse de courrier électronique, date de naissance, raison sociale) dès lors que vous
                déclarez un sinistre, créez un compte ou que vous vous nous contactez. Ces données sont également
                confidentielles ; seuls Anavel et l’hébergeur du site en ont connaissance.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-link fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Liens hypertextes avec des sites tiers</span></v-card-title
              >
              <v-card-text>
                <a href="https://mes-services.anavel.bzh">https://mes-services.anavel.bzh</a> n’exerce aucun contrôle
                sur le contenu des sites tiers. L’existence d’un lien hypertexte entre Anavel et un site tiers ne
                signifie en aucune manière le contrôle du contenu du site tiers.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-copyright fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Propriété intellectuelle</span>
              </v-card-title>
              <v-card-text>
                <p>
                  Toute représentation totale ou partielle du site
                  <a href="https://mes-services.anavel.bzh">https://mes-services.anavel.bzh</a> et/ou de son contenu par
                  quelques procédés et sur quelques supports que ce soient, sans autorisation préalable et expresse de
                  son Responsable de Publication est interdite et constitue une contrefaçon sanctionnée notamment par
                  les articles L335-2 et suivants du Code de la Propriété intellectuelle.
                </p>
              </v-card-text>
              <v-card-text>
                <p>
                  La librairie
                  <strong>"Font Awesome"</strong> est utilisée sur ce site conformément aux termes de la
                  <strong>licence Creative Commons Attribution 4.0 International (CC BY 4.0)</strong>.
                </p>
                <p>
                  "Font Awesome" est une collection d'icônes et de symboles disponibles gratuitement, créée par Dave
                  Gandy. Ces icônes peuvent être utilisées et modifiées librement, que ce soit à des fins commerciales
                  ou non commerciales, à condition que l'auteur original soit crédité de manière appropriée. Pour plus
                  d'informations sur la licence Creative Commons Attribution 4.0 International, veuillez consulter le
                  lien suivant : <a href="https://creativecommons.org/licenses/by/4.0/">Termes de la licence</a>
                </p>
                <p>
                  Pour plus d'informations sur "Font Awesome" et pour obtenir la version complète de la licence,
                  veuillez visiter le site officiel : <a href="https://fontawesome.com/">Site officiel fontawesome</a>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                <v-avatar color="EoleBlue" size="48" class="mr-2">
                  <i class="fa-solid fa-pen-to-square fa-1x white--text"></i>
                </v-avatar>
                <span class="text-h6">Modification du site</span>
              </v-card-title>
              <v-card-text>
                Anavel se réserve le droit de modifier, de corriger et/ou de mettre à jour le contenu de ce site et de
                ses mentions légales à tout moment et ceci sans préavis.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-avatar color="EoleBlue" size="48" class="mr-2">
              <i class="fa-solid fa-money-bill-transfer fa-1x white--text"></i>
            </v-avatar>
            <span class="text-h6"> Moyens de Paiement </span>
          </v-card-title>
          <v-card-text>
            Pour faciliter vos achats sur Mes Services Assurance, nous avons choisi
            <a href="https://stripe.com/fr">Stripe</a>, un leader mondial des solutions de paiement en ligne, pour gérer
            en toute sécurité les transactions financières. <br />
            En utilisant notre service, vous acceptez que Stripe traite les paiements, les vérifications de cartes de
            crédit, et les préventions de fraudes en notre nom. <br />
          </v-card-text>
          <v-card-title> Traitement des Données par Stripe </v-card-title>
          <v-card-text>
            Pour réaliser une transaction, certaines de vos données personnelles doivent être transmises à Stripe,
            incluant les informations nécessaires à l'exécution du paiement telles que le montant de la transaction, les
            informations de paiement (numéro de carte de crédit, date d’expiration, etc.), ainsi que votre nom, prénom
            et votre adresse email. Stripe utilise et traite vos données conformément à sa politique de confidentialité,
            disponible sur
            <a href="https://stripe.com/fr/privacy">https://stripe.com/fr/privacy</a>.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Éditeur du site",
        },
      ],
      firstname: "",
      lastname: "",
      formField: [
        {
          identity: {
            firstname: "",
            lastname: "",
          },
          address: {},
        },
      ],
    }
  },

  setup() {
    return {}
  },
}
</script>
